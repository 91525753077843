<template>
  <section class="result-wrapper single-page">
  <div class="full-width">
      <div class="row">
        <div class="col-6 login-banner">
          <h2>{{ t('login.title') }}</h2>
          <img src="img/account-image.png">
        </div>
        <div class="col-6 login-form">
            <div>
              <ul class="tabs">
                <li class="active">
                {{ t('login.label') }}
                </li>
              <nuxt-link to="/register">
<li>{{ t('register.label') }}</li>
</nuxt-link>
              </ul>
              <form @submit.prevent="formSubmit">
                <div class="form-group">
                <div class="btn-group btn-group-toggle mb-3" data-toggle="buttons">
                  <label class="btn active">
                    <input v-model="login_by" type="radio" value="email">{{ t('login.by_email') }}
                  </label>
                  <label class="btn">
                    <input v-model="login_by" type="radio" value="mobile">{{ t('login.by_mobile') }}
                  </label>
                </div>
                <input
                  v-if="login_by=='email'"
                  :placeholder="t('login.email')"
                  v-model="email"
                  class="form-control"
                  type="email"
                  required
                  autocomplete="off"
                >
                <div v-else class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">+852</span>
                  </div>
                  <input
                  v-if="login_by=='mobile'"
                  :placeholder="t('login.mobile')"
                  v-model="mobile"
                  class="form-control mobile"
                  type="number"
                  required
                  autocomplete="off"
                >
                </div>
              </div>
                <div class="form-group">
                  <input v-model="password" :placeholder="t('login.password')" class="form-control" type="password" required>
                </div>
                <button type="submit" class="btn btn-primary">
{{ t('login.button') }}
</button>
              </form>
              <div class="social-connect">
                <a :href="gg_connect"><img src="img/icons/icon-google.png"></a>
                 <a :href="fb_connect"><img src="img/icons/icon-facebook-blue.png"></a>
              </div>
              <p>
                <nuxt-link to="/login/forgotpassword">{{t('login.forgot_password')}}</nuxt-link>
              </p>
            </div>
        </div>
    </div>
  </div>
</section>
</template>
<script>
import commonFunc from '~/assets/js/commonHelper.js'
export default {
  components: {
  },
  data: function () {
    return {
      gg_connect: '#',
      fb_connect: '#',
      submitting: false,
      login_by: 'email',
      login: '',
      password: ''
    };
  },
  layout(context) {
    return "default";
  },
  head() {
    return {
      ...commonFunc.getSeoMeta(this.$store.state.layout.seo),
      bodyAttrs: {
        class: 'login-page'
      }
    }
  },
  computed: {},
  watch: {
  },
  async asyncData({ store, route, context }) {
    await store.dispatch("layout/fetchSeo", {
      path: route.path
    });
  },
  mounted() {
    this.gg_connect = process.env.GG_CONNECT;
    this.fb_connect = process.env.FB_CONNECT;
    try {
      $(window).resize()
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    formSubmit: function (e) {
      this.errors = []
      const data = {
        login: this.login_by === 'email' ? this.email : '+852' + this.mobile,
        password: this.password
      }
      if (this.submitting) {
        e.preventDefault();
        return
      }
      this.submitting = true;
      const vm = this
      vm.$axios
        .post("/auth/login", data)
        .then(function (response) {
          if (response.data.success) {
            const token = response.data.data.accessToken;
            commonFunc.authorize(token, vm.$axios);
          } else {
            commonFunc.toast(response.data.message, 'danger');
            vm.submitting = false;
          }
        }).catch((e) => {
          // commonFunc.toast(vm.t('blog.subscribe_fail'), 'danger')
          // vm.submitting = false;
        });
      e.preventDefault();
      return false;
    }
  },
};
</script>